/** 专利 */
import request from '@/utils/request.js';

// 专利筛选条件
export const getPatentFilters = (keywords) => {
  return request({
    method: 'post',
    url: '/patentanalysis',
    data: {
      keywords
    }
  })
}

// 专利列表
export const getPatentList = (data) => {
  return request({
    method: 'post',
    url: '/patents',
    headers: {
      token: true
    },
    data
  })
}

// 专利详情
export const getPatentDetails = (id) => {
  return request({
    method: 'post',
    url: '/patentinfo',
    headers: {
      token: true
    },
    data: {
      id
    }
  })
}

// 专利监控
export const setPatentMonitor = (data) => {
  return request({
    method: 'post',
    url: '/usermonitorpatent',
    headers: {
      token: true
    },
    data
  })
}

// 年费监控
export const setPatentMonitorAnnualCost = (data) => {
  return request({
    method: 'post',
    url: '/usermonitorpatentannualfee',
    headers: {
      token: true
    },
    data
  })
}

// 取消年费监控
export const delPatentMonitorAnnualCost = (data) => {
  return request({
    method: 'post',
    url: '/delusermonitortannualfee',
    headers: {
      token: true
    },
    data
  })
}

// 年费监控列表
export const getPatentAnnualCostList = (data) => {
  return request({
    method: 'get',
    url: '/annualfeelist',
    headers: {
      token: true
    },
    params: data
  })
}

// 减费确认
export const setAnnualCostReduce = (id, reduction_fee) => {
  return request({
    method: 'post',
    url: '/monitorreductionfee',
    headers: {
      token: true
    },
    data: {
      id,
      reduction_fee
    }
  })
}

// 缴费确认
export const setAnnualCostConfirm = (id) => {
  return request({
    method: 'post',
    url: '/monitorpaycost',
    headers: {
      token: true
    },
    data: {
      id
    }
  })
}

// 获取专利年费提醒时间
export const getAnnualCostFeeremid = () => {
  return request({
    method: 'get',
    url: '/getannualfeeremid',
    headers: {
      token: true
    },
  })
}

// 设置专利年费提醒时间
export const setAnnualCostFeeremid = (data) => {
  return request({
    method: 'post',
    url: '/dealannualfee',
    headers: {
      token: true
    },
    data
  })
}

// 法律状态信息历史
export const getPatentLawHistory = (id) => {
  return request({
    method: 'post',
    url: '/patentlawinfo',
    data: {
      id
    }
  })
}

// 专利审查列表
export const getPatentDecision = (id) => {
  return request({
    method: 'post',
    url: '/patentdecision',
    data: {
      id
    }
  })
}

// 专利审查详情
export const getPatentDecisionDetails = (id) => {
  return request({
    method: 'post',
    url: '/patentdecisioninfo',
    data: {
      id
    }
  })
}

// 专利权转移信息
export const getPatentTransfer = (ANO, page) => {
  return request({
    method: 'post',
    url: '/patenttransinfo',
    data: {
      ANO,
      page
    }
  })
}
// 专利权许可信息
export const getPatentLicense = (ANO, page) => {
  return request({
    method: 'post',
    url: '/patentpermitinfo',
    data: {
      ANO,
      page
    }
  })
}
// 专利权质押信息
export const getPatentPledge = (ANO, page) => {
  return request({
    method: 'post',
    url: '/patentpledgeinfo',
    data: {
      ANO,
      page
    }
  })
}

// 专利引用/被引用数据
export const getPatentReference = (ANO, page) => {
  return request({
    method: 'post',
    url: '/patentcitsdata',
    headers: {
      token: true
    },
    data: {
      ANO,
      page
    }
  })
}

// 专利族
export const getPatentFamily = (PNS, page) => {
  return request({
    method: 'post',
    url: '/patentfamilydata',
    data: {
      PNS,
      page
    }
  })
}

// 企业专利图表
export const getEpPatentChart = (ASPO) => {
  return request({
    method: 'post',
    url: '/company',
    data: {
      ASPO
    }
  })
}
