/** 专利复审、无效页 */
<template>
  <div class="content law-info">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'Home', params: {queryType: 'patent'}}">专利查询</el-breadcrumb-item>
      <el-breadcrumb-item>专利{{lawType==='review'?'复审':'无效'}}详情</el-breadcrumb-item>
    </el-breadcrumb>
    <section class="justify-between">
      <div class="law-info-details back-fff">
        <h4 class="details-main-title">
          一种用于厨房改造的发明专利
        </h4>
        <ul class="details-main">
          <li class="details-main-item">
            <html-panel v-if="html" id="iframe1" :url.sync="html"></html-panel>
          </li>
        </ul>
      </div>
      <div class="law-info-text back-fff flex-1">
        <h4 class="law-text-title theme-back">专利{{lawType==='review'?'复审':'无效'}}信息</h4>
        <ul class="law-text-con back-fff">
          <li class="law-text-item ptb-20"><label>决定号：</label><span>{{lawInfo.ridn}}</span></li>
          <li class="law-text-item ptb-20"><label>决定日：</label><span>{{lawInfo.ridd}}</span></li>
          <li class="law-text-item ptb-20 flex"><label style="width:64px">请求人：</label><span>{{lawInfo.riapo}}</span>
          </li>
          <li class="law-text-item ptb-20 flex"><label>请求日：</label><span>{{lawInfo.ad}}</span></li>
          <li class="law-text-item ptb-20 flex"><label style="width:80px">委内编号：</label><span></span></li>
          <li class="law-text-item ptb-20 flex"><label style="width:80px">合议组长：</label><span>{{lawInfo.rileo}}</span>
          </li>
          <li class="law-text-item ptb-20 flex"><label>主审员：</label><span>{{lawInfo.rimeo}}</span></li>
          <li class="law-text-item ptb-20 flex"><label>参审员：</label><span>{{lawInfo.riaeo}}</span></li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
import HtmlPanel from '@/components/HtmlPanel.vue'
import { setItem, getItem } from '@/utils/storage.js'
import { getPatentDecisionDetails } from '@/api/patent'
export default {
  name: 'PatentLawInfo',
  components: {
    HtmlPanel
  },
  data () {
    return {
      lawType: true,
      lawInfo: {},
      html: '',
    }
  },
  mounted () {
    this.$store.dispatch('setQueryType','patent')
    this.lawType = this.$route.params.lawType || getItem('lawType', 'sessionStorage')
    setItem('lawType', this.lawType, 'sessionStorage')
    this.getPatentDecisionDetails()
  },
  methods: {
    async getPatentDecisionDetails () {
      try {
        let { data, status } = await getPatentDecisionDetails(this.$route.query.id)
        if (status == 200) {
          this.html = data[0].html
          this.lawInfo = { ...data[0].appeal, ridn: data[0].ridn, ...data[0].claimer[0], ...data[0].coexaminer[0] }
        }
      } catch (error) {
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 30px 0 20px;
}
.law-info-details {
  width: 67%;
  margin-right: 17px;
  padding: 40px;
  & > .details-main-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 90px;
  }
}
.law-info-text {
  .law-text-title {
    text-align: center;
    line-height: 60px;
    font-size: 18px;
  }
  .law-text-con {
    padding: 20px 18px;
    .law-text-item {
      font-size: 16px;
      label {
        width: 64px;
      }
      span {
        flex: 1;
      }
    }
  }
}
</style>