<template>
  <div :ref="id" v-html="html"> </div>
</template>
<script>
export default {
  // 使用时请使用 :url.sync=""传值
  props: {
    id: String,
    url: {
      required: true
    }
  },
  data () {
    return {
      html: '',
      retryTime: 1
    }
  },
  mounted () {
    this.load(this.url)

  },
  methods: {
    load (url) {
      if (url && url.length > 0) {
        let img_url = url.substr(0, url.lastIndexOf('/') + 1);
        let request_url = url.substr(url.lastIndexOf("PID") + 3)
        let param = {
          accept: 'text/html, text/plain'
        }
        this.$http.defaults.baseURL = process.env.VUE_APP_HTML_URL
        try {
          this.$http.get(request_url, param).then((response) => {
            // 处理HTML显示
            this.html = response.data
            this.$nextTick(() => {
              let dom = this.$refs[this.id];
              if (dom) {
                dom.querySelectorAll('img').forEach(item => {
                  let img_src = item.src.substr(item.src.lastIndexOf('/') + 1);
                  item.src = img_url + img_src
                  item.alt = '错误图片'
                });
              }
              if (this.id == 'iframe2') {
                dom.querySelectorAll('.Section1>a').forEach(item => {
                  item.querySelectorAll('b').forEach(i => {
                    i.style.display = "none"
                  })
                });
              }
            })
            this.retryTime += 1
          }).catch((err) => {
            if (!err.response && this.retryTime) {
              this.retryTime -= 1
              this.load(url)
            }
            this.html = '暂无数据'
          })
        } catch (error) {
          return false
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep p {
  line-height: 28px !important;
}
</style>